import React from "react"
import { Row, Col, Container, Badge, Card } from "react-bootstrap"
import SEO from "../components/Seo"
import { FaEnvelope } from "react-icons/fa"
import { FaCalendarAlt } from "react-icons/fa"
import { FaVideo } from "react-icons/fa"
import { FaFilter } from "react-icons/fa"
import { FaRegQuestionCircle } from "react-icons/fa"
import { FaCommentDots } from "react-icons/fa"
import { useTranslation } from "react-i18next"

const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <SEO
        title={t("meta.pageTitle")}
        keywords={t("meta.pageKeywords")}
        lang={t("meta.pageLangKey")}
        description={t("meta.pageDescription")}
      />
      <Container className="pb-5">
        <Row>
          <Col className="text-center">
            <p className="text-orange text-uppercase">
              {t("home.h2SubHeader")}
            </p>
            <h2>{t("home.h2Headline")}</h2>
          </Col>
        </Row>
        <Row className="mt-5 action-cards">
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center ">
                <Badge className="text-orange">
                  <FaEnvelope />
                </Badge>
                <a
                  href="https://www.allianz.de/service/kontaktformular/?ika-id=489#agentur=chr.beyer"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.buttonSendMessage")}
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center ">
                <Badge className="text-orange">
                  <FaCalendarAlt />
                </Badge>
                <a
                  href="https://onlinetermine-agentur.allianz.de/?key=95643a8b96bfc0de"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.buttonMakeAppointment")}
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center ">
                <Badge className="text-orange">
                  <FaVideo />
                </Badge>
                <a
                  href="https://www.allianz.de/service/onlineberatung/starten/"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.buttonConsulting")}
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center ">
                <Badge className="text-orange">
                  <FaFilter />
                </Badge>
                <a
                  href="https://www.allianz.de/business/service/schaden-melden/"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.buttonReportDamage")}
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center ">
                <Badge className="text-orange">
                  <FaRegQuestionCircle />
                </Badge>
                <a
                  href="https://www.allianz.de/service/"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.questions")}
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} lg={2} className="pb-lg-0 pb-3">
            <Card className="bg-light rounded h-100">
              <Card.Body className=" d-flex justify-content-center">
                <Badge className="text-orange">
                  <FaCommentDots />
                </Badge>
                <a
                  href="https://wa.me/49521986020/?text=Herzlich Willkommen im WhatsApp Chat der Allianz Beyer OHG"
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.videoChat")}
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default IndexPage
